<!-- 财务 -->
<template>
  <div class="box">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/message/finance' }"
        >财务</el-breadcrumb-item
      >
      <el-breadcrumb-item>账户</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col v-if="from.type!=2" :span="3">
          <el-button type="primary" round @click="showDialog(1)">
            充值
          </el-button>
          <el-button type="warning" round @click="showDialog(2)">
            扣款
          </el-button>
        </el-col>
        <el-col v-else :span="5">
          <div>余额：{{yue}}</div>
          <div>扣款：{{koukuan}}</div>
        </el-col>
        <el-col :span="15">
          <div style="line-height: 40px; font-size: 20px; font-weight: bold">
            {{ from.name }}
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="已入账" name="first">
          <el-table :data="recordListData" stripe style="width: 100%">
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="sum" label="金额"> </el-table-column>
            <el-table-column prop="AuditName" label="审核人"> </el-table-column>
            <el-table-column prop="ChangedTime" label="审核时间">
            </el-table-column>
            <el-table-column prop="AuditName" label="录入人">
            </el-table-column>
            <el-table-column prop="AuditTime" label="录入时间">
            </el-table-column>
            <el-table-column prop="type" label="类型">
              <template slot-scope="scope">
                {{scope.row.type=='1'?'充值':'扣款'}}-{{scope.row.submit}}
              </template>
            </el-table-column>
            <el-table-column prop="beizhu" label="备注"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="待审核" name="second">
          <el-table :data="recordEditListData" stripe style="width: 100%">
            <el-table-column prop="sum" label="金额"> </el-table-column>
            <el-table-column prop="AuditName" label="录入人"> </el-table-column>
             <el-table-column prop="ChangedTime" label="录入时间"> </el-table-column>
            <el-table-column prop="type" label="类型">
              <template slot-scope="scope">
                {{scope.row.type=='1'?'充值':'扣款'}}-{{scope.row.submit}}
              </template>
            </el-table-column>
            <el-table-column prop="beizhu" label="备注"> </el-table-column>
            <el-table-column v-if="from.type!=2"  label="操作" width="220">
              <template slot-scope="scope">
                <el-button @click="audit(scope.row,1)" type="primary" size="mini">
                  通过
                </el-button>
                <el-button
                  type="warning"
                  size="mini"
                  @click="audit(scope.row,0)">
                  拒绝
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <el-dialog
      :title="from.type == 1 ? '充值' : '扣款'"
      :visible.sync="isShowDialog"
    >
      <el-form
        :model="from"
        :rules="fromRules"
        ref="ticketForm"
        label-width="auto"
        class="demo-qudaoForm"
      >
        <el-form-item label="金额" prop="sum">
          <el-input v-model="from.sum" placeholder="请输入金额"></el-input>
        </el-form-item>

        <el-form-item label="类型" prop="submit">
          <el-select
            v-model="from.submit"
            placeholder="请选择类型"
            v-if="from.type == 1"
          >
            <el-option
              v-for="(item, index) in chongZhiType"
              :key="item.id"
              :label="item.type"
              :value="item.type"
            >
            </el-option>
          </el-select>
          <el-select v-model="from.submit" placeholder="请选择类型" v-else>
            <el-option
              v-for="(item, index) in kouKuanType"
              :key="item.id"
              :label="item.type"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备忘" prop="beizhu">
          <el-input
            type="textarea"
            resize="none"
            placeholder="请输入内容"
            :autosize="{ minRows: 4, maxRows: 6 }"
            v-model="from.beizhu"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="clickBtn">
            {{ from.type == 1 ? "充值" : "扣款" }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { createRecord, recordlist,editRecord,recordeditlist,qudaoByDid } from "@/network/channel.js";
import DateFormat from "@/utils/DateFormat.js";

export default {
  name: "report",
  data() {
    return {
      userName:sessionStorage.getItem("userName"),
      // 渠道列表
      recordListData: [],
      // 审核列表
      recordEditListData:[],
      // 当前tab
      activeName: "first",
      // 是否显示弹窗
      isShowDialog: false,
      // 弹窗内容
      from: {
        name: "",
        qudaoID: "", // 渠道id
        submit: "",
        type: 1, // 充值1，扣款0
        sum: "",
        beizhu: "",
        ChangedTime: 0,
        OperatorName: window.sessionStorage.getItem("userName"),
        AuditName: window.sessionStorage.getItem("userName"),
        AuditTime: new Date(),
        EntityAvailableState:30,
        userName:sessionStorage.getItem("userName"),
      },
      yue:0,
      koukuan:0,
      // 表单规则
      fromRules: {
        sum: [
          {
            required: true,
            message: "请输入金额",
            trigger: "change",
          },
        ],
        submit: [
          {
            required: true,
            message: "请选择类型",
            trigger: ["change", "blur"],
          },
        ],
        beizhu: [
          {
            message: "请输入内容",
            trigger: ["change", "blur"],
          },
        ],
      },
      chongZhiType: [
        { type: "转账", id: 1 },
        { type: "对冲", id: 2 },
      ],
      kouKuanType: [
        { type: "违规", id: 3 },
        { type: "消费", id: 4 },
      ],
    };
  },
  created() {
    this.from.name = this.$route.query.name;
    this.from.qudaoID = this.$route.query.id;
    this.from.type = this.$route.query.type;
    if (window.sessionStorage.getItem('cooperType')==='6'){
      this.from.type=2
      qudaoByDid({id:this.from.qudaoID,userid: this.from.qudaoID}).then(res=>{
        if (res.code===200){
          this.yue=res.data?.money||0
          this.koukuan=res.data?.fund||0
        }
      })
    }
    this.getRecordList(this.from.qudaoID);
    this.getRecordeditlist(this.from.qudaoID)
  },
  methods: {
    // 获取审核列表、
    async getRecordeditlist(id){
      this.recordEditListData=[]
      let res = await recordeditlist({ teamId: id,userid:this.from.type===2?this.from.qudaoID:'' })
      console.log('res: ', res);
      if(res.data && res.data.length > 0){
        this.recordEditListData = res.data
      }
    },
    //审核按钮
    audit(row,type){
      let that = this
      console.log('row,type: ', row,type);
      console.log("userName:",this.userName);
      this.$confirm(`请确认要${type==1?"通过":"拒绝"}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: "warning",
      })
        .then(() => {
          const loading = that.$loading({
            lock: true,
            text: "请求中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that.editRecordFn(row,type)
          loading.close();
        })
        .catch(() => {
          console.log("用户点击取消");
        });
    },
    // 审核按钮
    async editRecordFn(from,type){
      console.log('from: ', from);
      let _from=JSON.parse(JSON.stringify(from))
      _from.EntityAvailableState = 10
      _from.sum = parseInt(from.sum)
      _from.type = this.from.type
      _from.action=type==1?'agree':'refund'
      let res =await editRecord(_from)
      console.log('res: ', res);
      if(res.code == 200 && res.msg == "success"){
        this.$message({
          message: "提交成功",
          type: "success",
        });
        this.getRecordeditlist(this.from.qudaoID)
        this.getRecordList(this.from.qudaoID);

      }
    },
    // 提交
    clickBtn() {
      this.$refs.ticketForm.validate(async (valid) => {
        if (valid) {
          this.from.ChangedTime = new DateFormat(new Date()).toString(
            "yyyy-mm-dd hh:ff:ss"
          );
          console.log("this.from: ", this.from);
          this.from.EntityAvailableState = 30
          let res = await createRecord(this.from);
          console.log("res: ", res);
          if (res.code == 200 && res.msg == "success") {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.isShowDialog = false;
            this.activeName = "second";
            // this.getRecordList(this.from.qudaoID);
            this.getRecordeditlist(this.from.qudaoID)

          }
        } else {
          this.$message({
            message: "请检查填写信息",
            type: "error",
          });
          return false;
        }
      });
    },
    // 打开弹窗
    showDialog(type) {
      this.isShowDialog = true;
      this.$refs['ticketForm'].resetFields()
      this.from.type=type
    },
    // 切换tab
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 获取渠道
    async getRecordList(id) {
      this.recordListData = [];
      let res = await recordlist({ teamId: id,userid:this.from.type===2?this.from.qudaoID:''});
      console.log("res: ", res);
      if (res.data && res.data.length > 0) {
        this.recordListData = res.data;
      }
    },
  },
};
</script>

<style scoped>
.box {
}
</style>
